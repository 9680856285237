import React from 'react';
import Loadable from 'react-loadable';
import { LoadingPage } from '@/components/loading/loadingBase';
import HeaderMetadata from '@/layouts/HeaderMetadata';

const QrCodeImageLoadble = Loadable({
  loader: () => import('@/sections/QRCodeImage'),
  loading: (props) => <LoadingPage isLoading={true} error={props.error} />,
});
const QRCodeImage: React.FC = () => {
  return (
    <React.Fragment>
      <HeaderMetadata title="QR Code Image" />
      <QrCodeImageLoadble />
    </React.Fragment>
  );
};

export default QRCodeImage;
